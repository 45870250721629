import styles from '../../Login.module.scss';
import { RouteName } from 'routes';
import { DocumentTitle, useDocumentTitle } from 'components/objects/documentTitle';
import { useSanaTexts, useSimpleTexts } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { Placeholder } from 'components/primitives/placeholders';
import { useSelector } from 'react-redux';
import { getCheckoutPageTitleTextKey } from '../../helpers';

type Props = {
  isDesignerMode?: boolean;
};

const PageTitleBlock = ({ isDesignerMode }: Props) => {
  const backTo = useSelector(s => s.page.backTo);
  const isQuotePromotion = backTo?.routeData.routeName === RouteName.QuotePromotion;
  const isCheckout = backTo?.routeData.routeName === RouteName.Checkout;
  const continueToKey = (isCheckout || isQuotePromotion)
    && getCheckoutPageTitleTextKey(isQuotePromotion, isCheckout && !!backTo!.routeData.params?.asQuote);

  const [title, continueToTitle] = useSimpleTexts(['Login_Title', continueToKey]).texts;
  const [loginToContinueTitle] = useSanaTexts([continueToKey && 'Login_To_Continue_Title']).texts;

  const pageTitle = continueToKey
    ? loginToContinueTitle && continueToTitle && makeSimpleText(loginToContinueTitle, [continueToTitle])
    : title;
  const documentTitle = useDocumentTitle(pageTitle);

  return (
    <>
      {documentTitle && !isDesignerMode && <DocumentTitle title={documentTitle} />}
      <h1 className={styles.pageTitle}>{pageTitle || <Placeholder />}</h1>
    </>
  );
};

export default PageTitleBlock;
