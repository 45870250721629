export default Object.freeze([
  {
    id: '0e2k0if6n',
    spacing: {
      margin: '9px 0',
      padding: '0',
    },
    columns: [
      {
        id: '0l2xk56yo',
        contentBlocks: [
          {
            id: 'gu949ntal',
            name: 'SanaText',
            model: {
              key: 'NewsletterUnsubscribe_Title',
              styleMode: 'Heading1',
            },
          },
        ],
      },
    ],
  },
  {
    id: 'd68e78kal',
    spacing: {
      margin: '13px 0',
      padding: '0',
    },
    columns: [
      {
        id: 'mpgqr6md7',
        contentBlocks: [
          {
            id: '8h8khz53c',
            name: 'SanaText',
            packageId: null,
            model: {
              key: 'NewsletterUnsubscribe_Description',
              styleMode: 'None',
            },
          },
        ],
      },
    ],
  },
  {
    id: '2fd8dawlp',
    spacing: {
      margin: '0 0 20px',
      padding: '0',
    },
    columns: [
      {
        id: 'znu2aoshm',
        colspan: {
          sm: 2,
          md: 2,
          lg: 2,
        },
        weight: 2,
        contentBlocks: [
          {
            id: '54r1ak1aj',
            name: 'SanaText',
            model: {
              key: 'EmailAddress',
              styleMode: 'None',
            },
          },
        ],
      },
      {
        id: 'sa4608emp',
        colspan: {
          sm: 5,
          md: 5,
          lg: 5,
        },
        weight: 5,
        contentBlocks: [
          {
            id: 'blsujbv6q',
            name: 'NewsletterUnsubscription',

            model: {
              unsubscribeBtnDisplayMode: 1,
            },
          },
        ],
      },
      {
        id: '8b5imjx5q',
        colspan: {
          sm: 5,
          md: 5,
          lg: 5,
        },
        weight: 5,
        contentBlocks: [],
      },
    ],
  },
]);
