import type { RowContentElement } from 'behavior/content';
import MobileTemplate from './MobileTemplate';
import DesktopTemplate from './DesktopTemplate';
import { Media } from 'components/responsive';

type Props = {
  desktop: RowContentElement[] | null;
  mobile: RowContentElement[] | null;
};

export default function newsletterSubscribeRenderer({ desktop, mobile }: Props) {
  return (
    <>
      <Media lessThan="md">
        <MobileTemplate mobile={mobile} />
      </Media>
      <Media greaterThanOrEqual="md">
        <DesktopTemplate desktop={desktop} />
      </Media>
    </>
  );
}
