import styles from '../../Login.module.scss';
import { SanaLinkButton } from 'components/primitives/links';
import { useSelector } from 'react-redux';
import { routesBuilder } from 'routes';
import { SimpleText, RichText } from 'components/sanaText';

type Props = {
  isDesignerMode?: boolean;
};

const CreateAccountButtonBlock = ({ isDesignerMode }: Props) => {
  const registrationAllowed = useSelector(s => s.settings.loaded && s.settings.profile.registrationAllowed);

  if (!registrationAllowed && !isDesignerMode)
    return null;

  return (
    <div className={styles.newAccount}>
      <h2 className={styles.title}><SimpleText textKey="Login_NotaCustomerYetHeader" /></h2>
      <div className={styles.block}><RichText textKey="Login_NotaCustomerYetIntro" /></div>
      <SanaLinkButton
        to={routesBuilder.forRegistration}
        textKey="Login_CreateAccountButton"
        className={styles.newAccountBtn}
      />
    </div>
  );
};

export default CreateAccountButtonBlock;
