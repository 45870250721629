export default Object.freeze([
  {
    id: 'i4384zvot',
    spacing: {
      margin: '9px 0',
      padding: '0',
    },
    columns: [
      {
        id: 'uf3dihns6',
        contentBlocks: [
          {
            id: 'ajx7u62zo',
            name: 'SanaText',
            model: {
              key: 'NewsletterSubscribe_Title',
              styleMode: 'Heading1',
            },
          },
        ],
      },
    ],
  },
  {
    id: 'qzqhkneec',
    spacing: {
      margin: '13px 0',
      padding: '0',
    },
    columns: [
      {
        id: 'llwu6qq4z',
        contentBlocks: [
          {
            id: 'njpmay8tm',
            name: 'SanaText',
            packageId: null,
            model: {
              key: 'NewsletterSubscribe_Description',
              styleMode: 'None',
            },
          },
        ],
      },
    ],
  },
  {
    id: 'ln0i7q932',
    spacing: {
      margin: '0 0 20px',
      padding: '0',
    },
    columns: [
      {
        id: 'gsb5caj9f',
        colspan: {
          sm: 2,
          md: 2,
          lg: 2,
        },
        weight: 2,
        contentBlocks: [
          {
            id: 'c6un70rix',
            name: 'SanaText',
            model: {
              key: 'EmailAddress',
              styleMode: 'None',
            },
          },
        ],
      },
      {
        id: 'osrcj9toi',
        colspan: {
          sm: 5,
          md: 5,
          lg: 5,
        },
        weight: 5,
        contentBlocks: [
          {
            id: 'g7ntedfgc',
            name: 'NewsletterSubscription',
            model: {
              showDescription: false,
              showTitle: false,
              subscribeBtnDisplayMode: 1,
            },
          },
        ],
      },
      {
        id: 'mjvg0oxx9',
        colspan: {
          sm: 5,
          md: 5,
          lg: 5,
        },
        weight: 5,
        contentBlocks: [],
      },
    ],
  },
]);
