export default Object.freeze([
  {
    id: 'vztri16fc',
    spacing: {
      margin: '0 0 9px',
      padding: '0',
    },
    columns: [
      {
        id: '2i8whb8v3',
        contentBlocks: [
          {
            id: 'xlyzccpb9',
            name: 'SanaText',
            model: {
              key: 'NewsletterUnsubscribe_Title',
              styleMode: 'Heading1',
            },
          },
        ],
      },
    ],
  },
  {
    id: 'oyby2ii6j',
    spacing: {
      margin: '13px 0',
      padding: '0',
    },
    columns: [
      {
        id: '9e9np0nd6',
        contentBlocks: [
          {
            id: 'r4zlaajz5',
            name: 'SanaText',
            model: {
              key: 'NewsletterUnsubscribe_Description',
              styleMode: 'None',
            },
          },
        ],
      },
    ],
  },
  {
    id: '29l8vwobo',
    spacing: {
      margin: '0 0 20px',
      padding: '0',
    },
    columns: [
      {
        id: '4bzw0t4wt',
        contentBlocks: [
          {
            id: 'ysiu89wp7',
            name: 'SanaText',
            model: {
              key: 'EmailAddress',
              styleMode: 'None',
            },
          },
          {
            id: '4hasob5mt',
            name: 'NewsletterUnsubscription',

            model: {
              unsubscribeBtnDisplayMode: 1,
            },
          },
        ],
      },
    ],
  },
]);
