export default Object.freeze([
  {
    id: '440dko7qt',
    spacing: {
      margin: '0 0 9px',
      padding: '0',
    },
    columns: [
      {
        id: 'jigwx4pr8',
        contentBlocks: [
          {
            id: 'gtxg2eqzq',
            name: 'SanaText',
            model: {
              key: 'NewsletterSubscribe_Title',
              styleMode: 'Heading1',
            },
          },
        ],
      },
    ],
  },
  {
    id: 'o62qdgcgw',
    spacing: {
      margin: '13px 0',
      padding: '0',
    },
    columns: [
      {
        id: 'pby5dvj1f',
        contentBlocks: [
          {
            id: 'megtgjxp5',
            name: 'SanaText',
            model: {
              key: 'NewsletterSubscribe_Description',
              styleMode: 'None',
            },
          },
        ],
      },
    ],
  },
  {
    id: 'yyxg6o1ob',
    spacing: {
      margin: '0 0 20px',
      padding: '0',
    },
    columns: [
      {
        id: 'twhcylxt2',
        contentBlocks: [
          {
            id: 'crre241ut',
            name: 'SanaText',
            model: {
              key: 'EmailAddress',
              styleMode: 'None',
            },
          },
          {
            id: '2g4omq3l0',
            name: 'NewsletterSubscription',
            model: {
              showDescription: false,
              showTitle: false,
              subscribeBtnDisplayMode: 1,
            },
          },
        ],
      },
    ],
  },
]);
